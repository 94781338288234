import * as React from "react"
import { Box, Container, Title, LinkGatsby } from "../../elements"
import { ListButtonsContrastCheckers } from "../../partials/admin/ListButtonsContrastCheckers"

export default function AllButtonsContrastCheckers() {
  return (
    <>
      <Box py={10}>
        <Container maxWidth="l">
          <Title>All reports</Title>
          <Box mt={2} />
          <LinkGatsby to="/admin/latest">Latest Reports</LinkGatsby>
          <Box mt={4} />
          <ListButtonsContrastCheckers latest={false} />
        </Container>
      </Box>
    </>
  )
}
